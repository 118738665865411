// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function list(
  data: any,
  options?: { [key: string]: any },
) {
  const params = data;
  delete params.flag;
  if(localStorage.getItem("topTemplateId")){
    // @ts-ignore
    params.topTemplateId = parseInt(localStorage.getItem("topTemplateId"))
  }
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any ;
  }>('/api/report/user/number/list', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
  if (msg.retCode === 0 && msg.data) {
    return msg.data.data.datas
  } else {
    return []
  }
}

export async function getPdfUrl(data:any, options?: { [key: string]: any }) {
  return await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: any;
  }>('/api/open/getPdfUrl', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
}

export function downloadPdf(record: any, url: string) {
  let link = document.createElement("a");
  link.style.display="none"
  link.href = url;
  link.target = "_blank";
  link.download = `${record.code}.pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}